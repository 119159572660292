import routes from '../Router/RootRoutes'

interface iGlobalState {
  routes: any
  drawerOpen: boolean
}
export const GlobalInitialState: iGlobalState = {
  routes: routes,
  drawerOpen: true,
}

export function RootReducer(state, action) {
  switch (action.type) {
    case 'routes':
      return {
        ...state,
        routes: action.payload,
      }
    case 'drawerOpen':
      return { ...state, drawerOpen: action.payload }
    default:
      throw new Error()
  }
}
